import { Injectable } from '@angular/core';
import { RestApiService } from './../../services/rest-api.service';
import { Observable } from 'rxjs';

@Injectable()
export class AssignSearchService {

  constructor(private restApiService: RestApiService) { }

  searchAssignment(url: string): Observable<any> {
    return this.restApiService.get('assign-search', url);
  }

}
