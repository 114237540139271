<div class="p-grid align-items-center vertical-container full-height">
  <div class="p-col-5 p-col-nogutter">
    <div class="full-height left-panel">
    <div class="login-form">
      <img class="ila-image" src="../../../assets/images/union-connect-logo@2x.png">
      <div class="p-col-12 p-col-nogutter">
        <label class="label-welcome-back">Welcome Back</label>
      </div>
      <div class="p-col-12 p-col-nogutter">
        <img class="loginimage" src="../../../assets/images/loginimage.png">
      </div>

      <form [formGroup]="signInForm">
        <div class="form-container p-grid">
          <div class="form-errors">
            <div
              [hidden]="!loginErrors.general.hasError"
              class="alert alert-danger"
            >
              <div *ngFor="let ErrorMessage of loginErrors.general.error">
                {{ ErrorMessage.message }}
              </div>
            </div>
          </div>

          <div class="form-field p-col-9 margin-bottom">
            <div class="form-label">
              <label class="lbl-usrname" for="email" translate>Username</label>
            </div>
            <div class="form-control">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <img class="user-icon" src="../../../assets/images/user.png">
                </span>
                <input
                  pInputText
                  formControlName="email"
                />
              </div>
            </div>
            <div
              class="form-errors"
              *ngIf="signInForm.controls.email.errors && (signInForm.controls.email.dirty || signInForm.controls.email.touched)"
            >
              <div
                [hidden]="!signInForm.controls.email.errors.required"
                translate
              >
                username_required
              </div>
            </div>
          </div>

          <div class="form-field p-col-9 margin-bottom">
            <div class="form-label">
              <label for="password" translate> Password </label>
            </div>
            <div class="form-control">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <img class="key-icon" src="../../../assets/images/key.png">
                </span>
                <p-password formControlName="password" [feedback]="false" ></p-password>
            </div>
              <div
                [hidden]="!loginErrors.password.hasError"
                class="alert alert-danger"
              >
                <div *ngFor="let ErrorMessage of loginErrors.password.error">
                  {{ ErrorMessage.message }}
                </div>
              </div>
            </div>
            <div
              class="form-errors"
              *ngIf="
                signInForm.controls.password.errors &&
                (signInForm.controls.password.dirty ||
                  signInForm.controls.password.touched)
              "
            >
              <div [hidden]="!signInForm.controls.password.errors.required">
                {{ "Password is required" }}
              </div>
            </div>
          </div>
          <div class="form-buttons p-col-9 padding-buttons">
            <u>
              <a
                (click)="forgotPassword()"
                class="forgot-password-link"
                translate
              >
              forgot_password
              </a>
            </u>
          </div>
          <div class="form-buttons p-col-9 padding-buttons">
            <button
              pButton
              label="Sign In"
              type="button"
              class="sign-in-button"
              [disabled]="!signInForm.valid"
              (click)="doSignIn()"
            ></button>
          </div>
        </div>
      </form>
    </div>
    </div>
  </div>
  <div class="p-col-7 p-col-nogutter right-panel-div">
    <img class="right-panel-image" src="../../../assets/images/group-92.png">
  </div>
</div>

<p-toast position="top-right"></p-toast>