<app-loader></app-loader>
<div>
  <app-header class="top-bar p-grid p-col-nogutter"></app-header>

  <!-- <p-sidebar [(visible)]="sidebarVisible1" position="left"> -->
    <div *ngIf="!isSuperAdmin" class="sidebar" [class]="sidebarMenuClass" (mouseover)="collapseMenu()" (mouseleave)="hideMenu()">
      <app-sidebar [isCollapsed]="isCollapsed" ></app-sidebar>
    </div>
    <div *ngIf="isSuperAdmin" class="sidebar" [class]="sidebarMenuClass" (mouseover)="collapseMenu()" (mouseleave)="hideMenu()">
      <app-super-admin-sidebar [isCollapsed]="isCollapsed" ></app-super-admin-sidebar>
    </div>
  <!-- </p-sidebar> -->

  <div class="main-panel">
    <div class="main-container" [class]="mainContentClass">
      <div class="main-content" >
        <router-outlet></router-outlet>
      </div>

    </div>
  </div>
</div>

<p-toast position="top-right"></p-toast>