import { Injectable } from '@angular/core';
import { RestApiService } from '../shared/services/rest-api.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constructor(private restApiService: RestApiService) {}
  public profileUrl : any;

  sendInvite(data:any): Observable<any>{
    return this.restApiService.post('sendInvite' ,'/api/secure/members.json', data , 'loader')
  }

  getProfileDetails(): Observable<any>{
    return this.restApiService.get('profile', '/api/secure/admin/profile.json');
  }

  saveProfileDetails(data:any): Observable<any>{
    return this.restApiService.post('saveProfileDetails' ,'/api/secure/admin/profile.do', data , 'loader')
  }


  public UploadProfile(data:any){
    return this.restApiService.uploadFile('upload profile image' , '/api/secure/homelocal/officials/upload.json' , data);
  }


}
