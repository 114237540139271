<div class="ui-g">
  <div class="p-col-12">{{headerStr}}</div>
  <div class="p-col-9">
    <p-chart height="100" type="bar" [data]="buildData" [options]="options"></p-chart>
  </div>
  <div class="p-col-3">
    <label translate>label_month</label>
    <p-dropdown [options]="monthList" [(ngModel)]="selectedMonth" (onChange)="refreshData()">
    </p-dropdown>
    <label translate>label_year</label>
    <p-dropdown [options]="yearList" [(ngModel)]="selectedYear" (onChange)="refreshData()">
    </p-dropdown>
  </div>
</div>