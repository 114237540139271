<div #dataTable (window:resize)="onResize($event)" [class]="divClass" *ngIf="showSearchAndActionBtn">

  <div class="search-and-export-data-panel p-grid">

    <div *ngIf="searchBar == 'top'" [class]="searchbarClass" class="search-bar-div">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input class="search-input" type="text" pInputText [(ngModel)]="search" (keyup)=onQuickFilterChanged($event)
          [placeholder]="searchPlaceholder" />
      </span>

    </div>
    <div [class]="statusClass" class="dropdown-one" *ngIf="isStatusDropdown">
      <div class="dropdown-one-label" translate>lbl_status</div>
      <p-dropdown [options]="optionsForDropDownOne" [optionLabel]="'value'" [optionValue]="'id'"
        (onChange)="statusFilter($event)" [(ngModel)]="selectedStatus"> </p-dropdown>
    </div>



    <div class="action-button p-col-7">
      <p-button *ngIf="showExportBtn" class="export-btn" [label]="exportBtnLabel" (click)="exportGrid($event)">
        <img src="../../../../../assets/images/excel.png" class="excel-icon">
      </p-button>
      <p-button *ngIf="showActionBtn" [label]="buttonLabel" class="add-btn" (click)="actionBtnClick($event, 'add')">
        <img *ngIf="isAddMember" class="add-button-icon" src="../../../../../assets/images/add-member2.png">
      </p-button>
    </div>

  </div>
</div>

<p-table #resetPage [lazy]="true" [rows]="paginationPageSize" [value]="datasource" [paginator]="pagination"
  [totalRecords]="totalRecords" (onLazyLoad)="loadDatasourceLazy($event)" [selectionMode]="rowSelection"
  (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" [(selection)]="selectedRecords"
  [rowsPerPageOptions]="rowsPerPage" [showFirstLastIcon]="false" class="p-col-12" responsiveLayout="scroll"  scrollHeight="flex" >


  <ng-template pTemplate="header">
    <tr>
      <ng-container *ngFor="let col of columnDefs">

        <th [width]="col.width" *ngIf="col.resize && enableResizer" pResizableColumn [pSortableColumn]="col.sort?col.field:null">
          {{col.header}}
          <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
        </th>

        <th *ngIf="!col.resize" [pSortableColumn]="col.sort ? col.field : null">
          {{col.header}}
          <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
        </th>
      </ng-container>
      <th style="width:50px" *ngIf="actionButtonsDefs.length"></th>


    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <tr class="" [attr.colspan]="columnDefs.length">
      <!-- <td> -->
      <div class="no-records" *ngIf="totalRecords === 0">
        <div class="center-div" translate> lbl_no_records_found </div>
      </div>
      <!-- </td> -->
    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="pageinator" translate>lbl_items_per_page</div>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columnDefs" let-rowIndex="rowIndex">

    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [class]="rowIndex%2==0?'even-row':'odd-row'">
      <td *ngFor="let col of columnDefs" [class]="col.class">
        <!-- {{rowData[col.field]}} -->

        <div innerHTML="{{rowData[col.field]}}"></div>

      </td>

      <td *ngIf="actionButtonsDefs.length" class="action-icons" (click)="actionBtnClick($event, rowData)">
        <div class="action-icon">
          <div class="grid-menu" appClickOutside (clickOutside)="close($event)">
            <div *ngFor="let btn of actionButtonsDefs" class="grid-menu-item">
              <div [class]="btn.class">{{btn.label}}</div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>

</p-table>
<!-- <div class="pagination-records">Items per page</div> -->