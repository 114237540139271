<div class="p-grid align-items-center vertical-container full-height">
    <div class="p-col-5 p-col-nogutter">
      <div class="full-height left-panel">
      <div class="login-form">
        <img class="ila-image" src="../../../assets/images/union-connect-logo@2x.png">
        <div class="p-col-12 p-col-nogutter">
          <label class="label-welcome-back" translate>thank_you</label>
        </div>
        <div class="p-col-12 p-col-nogutter instruction-div">
           <label class="instructions" translate>forgot_password_email_sent_success_msg</label>
        </div>
        <div class="form-buttons p-col-10">
            <button
              pButton
              label="Sign In"
              type="button"
              class="sign-in-button"
              (click)="goToSignIn()"
            ></button>
          </div>
      </div>
      </div>
    </div>
    <div class="p-col-7 p-col-nogutter right-panel-div">
      <img class="right-panel-image"  src="../../../assets/images/group-92.png">
    </div>
  </div>

  <p-toast></p-toast>