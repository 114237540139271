import { Component, OnInit, ViewChild } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { StorageService } from '../../services/storage.service';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public sidebarMenuClass = 'sidebar-shrink';
  public mainContentClass = 'expand-right-panel';
  @ViewChild(SidebarComponent) sidebarRef: SidebarComponent;
  isCollapsed = false;
  isSuperAdmin = false;
  constructor(
    private sidebarService: SidebarService,
    private storageService: StorageService,
  ) {}

  public ngOnInit(): void {
    const userRole = JSON.parse(this.storageService.getItem(AppSettings.USER_ROLE));
    this.isSuperAdmin = userRole === '1' ? true : false;
    this.sidebarService.sideSubject.subscribe(sidebar=>{
     if (sidebar === 'shrink-menu') {
        this.sidebarMenuClass = 'sidebar-shrink';
     } else {
        this.sidebarMenuClass = 'sidebar-expand';
     }
    })
  }

  collapseMenu(): void {
    this.sidebarMenuClass = 'sidebar-expand';
    this.isCollapsed = true;
  }

  hideMenu(): void {
    this.sidebarMenuClass = 'sidebar-shrink';
    this.isCollapsed = false;
  }
}
