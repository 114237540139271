import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { UserCommonService } from '../../modules/shared/services/user-common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  public isPasswordMatch = false;
  public token: any
  resetPasswordForm = new UntypedFormGroup({
    newPassword: new UntypedFormControl(),
    confirmNewPassword: new UntypedFormControl()
  });

  public constructor(
    private formBuilder: UntypedFormBuilder,
    private userCommonService: UserCommonService,
    private translate: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  public ngOnInit() {
    debugger
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
  });
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmNewPassword: ['', [Validators.required]],
    });
  }

  public matchPassword(): void {
    this.isPasswordMatch = false;
    if (this.resetPasswordForm.get('confirmNewPassword').value && this.resetPasswordForm.get('newPassword').value) {
      if (this.resetPasswordForm.get('confirmNewPassword').value === this.resetPasswordForm.get('newPassword').value) {
        return;
      } else {
        this.isPasswordMatch = true;
      }
    }
  }

  public resetPassword(): void {
    const resetPasswordRequest = {
      password: this.resetPasswordForm.get('newPassword').value,
      confirmPassword: this.resetPasswordForm.get('confirmNewPassword').value
    }
    this.userCommonService.resetPassword(resetPasswordRequest, this.token).subscribe(result => {
      this.messageService.add(
        {
          severity: 'success',
          summary: result.message,
          detail: result.messageType,
      });
      setTimeout(() => {
        this.router.navigate(['/signin']);
      }, 500);
    })
  }

}
