import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  TranslateService,
  TranslatePipe,
  TranslateDirective
} from '@ngx-translate/core';
import { AppSettings } from '../../../shared/app.settings';
import { StorageService } from './../../services/storage.service';
import { LoaderService } from './../../components/loader/loader.service';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file.uploader.component.html'
})
export class FileUploaderComponent implements OnInit {
  @Input() accept;
  @Input() uploadButtonLabel;
  @Output() beforeFileUploadEvent: EventEmitter<any> = new EventEmitter();
  @Output() afterFileUploadEvent: EventEmitter<any> = new EventEmitter();
  maxSize = AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE;
  fileUploadUrl: string = AppSettings.UPLOAD_FILE_URL;
  maxSizeMessage;
  message: Message[] = [];

  constructor(
    private storageService: StorageService,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private commonService: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.maxSizeMessage = this.commonService.getLabel('file_size_limit');
  }

  onUpload(event) {
    this.beforeFileUploadEvent.emit(event);
    const xhr = event.xhr;
    const response = JSON.parse(xhr.response);
    this.afterFileUploadEvent.emit(response);
    this.loaderService.hide();
  }

  onBeforeSend(event) {
    const xhr = event.xhr;
    xhr.setRequestHeader(
      AppSettings.HEADER_AUTHORIZATION,
      this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY)
    );
    this.loaderService.show('page-center');
  }

  onUploadError(event: any) {
    this.message = [];
    this.message.push({
      severity: 'error',
      summary: 'Error',
      detail: this.commonService.getLabel('file_upload_error')
    });
    this.loaderService.hide();
  }
}
