<div class="header-strip">
  <ul class="header--menu fadeInDown">
    <li class="user-profile" (click)="onProfileClick()">
      <a><img alt="Profile image" [src]="profileImage" /></a>
      <p>{{userName}}</p>
    </li>
    <li *ngIf="showSwitchLocal">
      <div class="switch-label">Switch Local</div>
      <div class="switch-local">
        <p-dropdown [options]="localIdList" [optionLabel]="'value'" [(ngModel)]="localValue" [optionValue]="'id'"
          (onChange)="onLocalChange($event)"></p-dropdown>
      </div>
    </li>
  </ul>
</div>