<p-fileUpload
  name="file[]"
  [url]="fileUploadUrl"
  auto="auto"
  (onUpload)="onUpload($event)"
  (onBeforeSend)="onBeforeSend($event)"
  (onError)="onUploadError($event)"
  ngDefaultControl
  [accept]="accept"
  [chooseLabel]="uploadButtonLabel"
  [maxFileSize]="maxSize"
  [invalidFileSizeMessageSummary]="maxSizeMessage"
>
</p-fileUpload>
