<div [hidden]="!show">
    <div [class]="cssClass">
        <div [hidden]="!show">
            <!-- <i class="fa fa-spinner fa-pulse fa-5x fa-fw"></i>
            <span class="sr-only">Loading...</span> -->
           <div  class="app-loading">
            <img  src="assets/images/Loader-Icon.svg"
            style="left:0%; width:100px; position:relative;">
                <svg class="spinner" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
             </div>
        </div>
    </div>
</div>