import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { AppSettings } from './../../app.settings';
import { UiService } from '../../services/ui.service';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { Component, OnInit, } from '@angular/core';
import { StorageService } from './../../services/storage.service';
import { UserCommonService } from './../../services/user-common.service';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import * as moment from 'moment';
import { UserProfileService } from '../../../user-profile/user-profile.service';
import { FormGroup } from '@angular/forms';
import { SimpleObject } from '../../models/simple-object';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  localIdList: Array<SimpleObject> = [];
  formGroup: FormGroup;
  notificationInterval;
  userFullName: string;
  items: MenuItem[];
  message: Message[] = [];
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  changePasswordPopup = false;
  editProfilePopup = false;
  subscription: Subscription;
  myDate = new Date(1496314866000);
  myDateLong = 1496314866000;
  minuts = 240;
  notifications: any = [];
  unReadNotificatonsCount = 0;
  limit = 5;
  offset = 0;
  showMoreOff = true;
  secondsCounter: any;
  logoutSession: any;
  swicthLocalValue: any;
  profileImg = "assets/images/profile-icon.png";
  localValue: any;
  activeLocal: any;
  showSwitchLocal = false;
  firstName: any;
  lastName: any;
  userName: any;
  profileImage: any;
  acceptImageTypes = AppSettings.ACCEPTED_IMAGE_FORMAT;
  userRole:any;

  public localListFromStorage: Array<string> = [];
  constructor(
    private uiService: UiService,
    private userCommanService: UserCommonService,
    private router: Router,
    private commonService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    public userService: UserProfileService,
    private ms: MessageService,

  ) { }

  ngOnInit() {

    this.getProfileImage();
    this.hideSwitchLocal();

    this.activeLocal = JSON.parse(
      this.storageService.getItem(AppSettings.ACTIVE_LOCAL)
    );
    this.localValue = this.activeLocal;
    // this.setSessionInterval();
    // this.storageService.logout.subscribe(res => {
    //   this.logout();
    // });

    // this.items = [
    //   {
    //     label: 'Logout',
    //     icon: 'fa fa-sign-out',
    //     command: event => {
    //       this.doSignOut();
    //     }
    //   }
    // ];

    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.userFullName = userObj.firstName + ' ' + userObj.lastName;
      if (
        userObj.userDetails !== undefined &&
        userObj.userDetails !== null &&
        userObj.userDetails.profileImage !== null
      ) {
        this.profileUrl =
          AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
      }
    }
  }

  getProfileImage() {
    this.userService.getProfileDetails().subscribe((result) => {
      if(result.messageType === "ERROR"){
        this.router.navigate(["/signin"]);

      }else{
        // this.storageService.setItem(
        //   AppSettings.ONBOARDING_ACCESS,
        //   result.profileDetails.onboardingAccess
        // );
        // this.storageService.setItem(
        //   AppSettings.ONBOARDING_WRITE_ACCESS,
        //   result.profileDetails.onboardingWriteAccess
        // );
        this.profileImage = AppSettings.BASE_URL + result.profileDetails.profileUrl;
        this.firstName = result.profileDetails.firstName;
        this.lastName = result.profileDetails.lastName;
        this.userName =
          this.firstName.replace(/["']/g, "") +
          " " +
          this.lastName.replace(/["']/g, "");
      }


    });
  }

  onLocalChange(local: any) {

    this.storageService.setItem(AppSettings.ACTIVE_LOCAL, local.value);
    const data = {}
    this.userCommanService.swicthLocal(local.value, data).subscribe(result => {
        location.reload();


    })

  }

  onProfileClick() {
    this.router.navigate(['app/profile/user-profile']);
  }

  // onSideMenuClick(event: any) {
  //   event.preventDefault();
  //   document.getElementsByTagName('body')[0].classList.toggle('closed');
  //   this.uiService.sidebarToggled();
  // }

  // doSignOut() {
  //   this.confirmationService.confirm({
  //     message: this.commonService.getLabel('msg_logout'),
  //     accept: () => {
  //       this.logout();
  //     }
  //   });
  // }




  // logout() {
  //   this.userCommanService.logout().subscribe((result) => {
  //     if (result?.messageType === "ERROR") {
  //       this.ms.add({
  //         severity: "error",
  //         summary: result.messageType,
  //         detail: result.message,
  //       });
  //     } else {
  //       this.ms.add({
  //         severity: "SUCCESS",
  //         summary: result.messageType,
  //         detail: result.message,
  //       });
  //       this.storageService.removeAll();
  //       this.storageService.removeAllCookies();
  //       this.router.navigate(["/signin"]);
  //     }
  //   });
  // }

  // onCancelEditProfile(event: any) {
  //   this.editProfilePopup = !this.editProfilePopup;
  // }

  // onCancelChangePassword(event: any) {
  //   this.changePasswordPopup = !this.changePasswordPopup;
  // }

  // errorMessage(error) {
  //   error = JSON.parse(error);
  //   this.message = [];
  //   this.message.push({
  //     severity: 'error',
  //     summary: 'error',
  //     detail: error.general[0].message
  //   });
  // }

  // setSessionInterval() {
  //   const sessionInterval = timer(10000, 1000);
  //   this.secondsCounter = sessionInterval.subscribe(n => {
  //     const idleSessionTime = this.storageService.getItem(AppSettings.INTERVAL);
  //     if (idleSessionTime) {
  //       if (idleSessionTime < new Date().getTime()) {
  //         this.confirmYouThere();
  //         this.secondsCounter.unsubscribe();
  //       } else {
  //         clearTimeout(this.logoutSession);
  //       }
  //     }
  //   });
  // }

  // confirmYouThere() {
  //   this.sessionTimeout();
  //   const message = this.commonService.getLabel('lbl_confirm_idle_session');
  //   this.confirmationService.confirm({
  //     message,
  //     rejectVisible: false,
  //     accept: () => {
  //       this.refreshSession();
  //     },
  //     reject: () => {
  //       this.refreshSession();
  //     }
  //   });
  // }

  // sessionTimeout() {
  //   this.logoutSession = setTimeout(() => {
  //     const idleSessionTime = this.storageService.getItem(AppSettings.INTERVAL);
  //     if (idleSessionTime < new Date().getTime()) {
  //       this.logout();
  //     } else {
  //       clearTimeout(this.logoutSession);
  //     }
  //   }, AppSettings.IDLE_TIME_ON_CONFIRM_MSG);
  // }

  // refreshSession() {
  //   const idleTime = moment()
  //     .add(AppSettings.IDLE_TIME, 'm')
  //     .valueOf();
  //   this.storageService.setItem('interval', idleTime);
  //   clearTimeout(this.logoutSession);
  //   // this.userCommandService.sesionRefresh().subscribe(() => { });
  //   this.setSessionInterval();
  // }

  hideSwitchLocal() {
    let userRole = JSON.parse(this.storageService.getItem(AppSettings.USER_ROLE));
    this.localListFromStorage = JSON.parse(this.storageService.getItem(AppSettings.LOCALS));

    if ( this.localListFromStorage === null || this.localListFromStorage.length <= 1 || userRole === AppSettings.SUPER_ADMIN_ROLE) {
      this.showSwitchLocal = false;
    } else {
      this.localListFromStorage.forEach(element => {
        this.localIdList.push({ id: element, value: element });
      });

      this.userCommanService.showSwitchLocal.subscribe(d => {
        this.showSwitchLocal = d;
      })
   }

    // if(JSON.parse(this.storageService.getItem(AppSettings.USER_ROLE)) === AppSettings.SUPER_ADMIN_ROLE){
    //   this.showSwitchLocal = false;
    // }else{
    //   this.userCommanService.showSwitchLocal.subscribe(d => {
    //     this.showSwitchLocal = d;
    //   })
    // }


  }


}
