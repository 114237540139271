import { Component } from '@angular/core';
import { UserProfileService } from './modules/user-profile/user-profile.service';
import { StorageService } from './modules/shared/services/storage.service';
import { AppSettings } from "./modules/shared/app.settings";
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  constructor(    private profileDetails: UserProfileService ,    private storageService: StorageService,    private router: Router,


    ) {
    this.profileDetails
        .getProfileDetails()
        .subscribe((result) => {
          this.storageService.setItem(
            AppSettings.USER_ROLE,
            result.profileDetails.userRole
          );
          this.storageService.setItem(
            AppSettings.ONBOARDING_ACCESS,
            result.profileDetails.onboardingAccess
          );
          this.storageService.setItem(
            AppSettings.ONBOARDING_WRITE_ACCESS,
            result.profileDetails.onboardingWriteAccess
          );

        });
   }


}
