import { AppSettings } from './../app.settings';
import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
declare const google: any;
@Directive({
  selector: '[appSearchLocation]'
})
export class SearchLocationDirective {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  private _el: HTMLElement;
  autocomplete: any;
  constructor(el: ElementRef) {
    this._el = el.nativeElement;
    const input = this._el;
    this.autocomplete = new google.maps.places.Autocomplete(input, {});
    this.autocomplete.setComponentRestrictions(
      { country: AppSettings.SUPPORTED_COUNTRY });
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      const placeObj = {
        name: place.name,
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        id: input.id
      };
      this.invokeEvent(placeObj);

    });
  }

  invokeEvent(place: any) {
    this.setAddress.emit(place);
  }
}
