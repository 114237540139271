import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class AppSettings {
  public static BASE_URL = environment.basePath;
  public static IS_IDLE_TIME = environment.idleTimeEnable;
  // public static IDLE_TIME = 60000;
  // public static IDLE_TIME_ON_CONFIRM_MSG = 60000;
  public static SUPER_ADMIN_ROLE = "1";
  public static LOCAL_ADMIN_ROLE = "3";
  public static INVITE_ACCESS: 1;
public static COMPANY_ADMIN_ROLE = "4";
  public static ASSOCIATION_ADMIN_ROLE = "5";
  public static PVW_ADMIN_ROLE = "6";
  public static ACCEPTED_IMAGE_FORMAT =
    "image/png, image/jpg, image/jpeg, image/heif";
  public static DEFAULT_PROFILE_IMAGE = "assets/images/default_profile.png";
  public static SELECTED_TAB_IN_OFFICIALS_COMMITTEES =
    "selectedTabInOfficialsCommittees";
  public static LOCAL_TOKEN_KEY = "Local_tokan";
  public static IDLE_TIME = 8;
  public static IDLE_TIME_ON_CONFIRM_MSG = 120000;
  public static INTERVAL = "interval";
  public static USER_ROLE = "user_role";
  public static API_SESSION = "api_session";
  public static WORKER_TAB = '0';
  public static API_ILASESSION = "ilaSession";
  public static ACCESS_TO_ONBOARDING = 1;
  public static NO_ACCESS_TO_ONBOARDING = 0;
  public static HEADER_CONTENT_TYPE = "application/json";
  public static HEADER_AUTHORIZATION = "Authorization";
  public static HEADER_TIMEZONE = "Timezone";
  public static HEADER_TIMEZONE_VALUE = "";
  public static HEADER_ACCEPT_LANGUAGE = "en-US";
  public static HEADER_AUTHORIZATION_VALUE = "";
  public static USER: any = null;
  public static TOKEN_KEY = "Token";
  public static USER_DETAILS = "userDetails";
  public static LOCALS = "locals";
  public static ACTIVE_LOCAL = "active_local";
  public static ONBOARDING_ACCESS = "onboarding_access";
  public static ONBOARDING_WRITE_ACCESS = "onboarding_write_Access";
  public static NO_ACCESS_TO_ONBOARDING_WRITE_ACCESS = 0;
  public static ACCESS_TO_ONBOARDING_WRITE_ACCESS = 1;
  public static DISTRICT = "district";
  public static FILE_UPLOAD_ID = "";
  public static FILE_UPLOAD_NAME = "";
  public static PROFILE_UPLOAD_ID = "";
  public static PROFILE_UPLOAD_NAME = "";
  public static FAQ: any = null;
  public static ACCESS_LIST = "ACCESS_LIST";
  public static FIRSTNAME = "firstName";
  public static LASTNAME = "lastName";
  public static ACCESS_MENU = "ACCESS_MENU";
  public static TIME_SETTINGS = "TIME_SETTINGS";
  public static MIN_DISTANCE = 0.1;
  public static MAX_DISTANCE = 1;
  public static UPLOAD_FILE_URL = AppSettings.BASE_URL + "/secure/file";
  public static GET_FILE_URL = AppSettings.BASE_URL + "/file";
  public static GET_FILE_THUMB_URL = AppSettings.BASE_URL + "/file/thumbnail_";
  public static EXPORT_URL = AppSettings.BASE_URL + "/";
  public static PRINT_URL = AppSettings.BASE_URL + "/";
  public static DEFAULT_PROFILE_IMAGE_URL =
    "/assets/images/default_profile.png";
  public static CLEAR_NOTIFICATION_INTERVAL: any;
  public static NOTIFICATION_INTERVAL: 600;
  public static IGNORE_LINK = [];
  public static SUPPORTED_COUNTRY: "india";
  /* eslint-disable no-useless-escape */
  public static WEBSITE_PATTERN = new RegExp(
    [
      "^(http://www.|https://www.|http://|https://)?",
      "[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})",
      "?(/.*)?$",
    ].join("")
  );
  public static NAME_PATTERN = new RegExp(["^[A-Za-z\\d-_\\s]*$"].join(""));
  public static ROLE_PATTERN = new RegExp(
    ["^[A-Za-z\\d-_\\s/\\\\]*$"].join("")
  );
  public static PHONE_PATTERN: any = "^[0-9][0-9]*$";
  public static NUMBER_PATTERN = /^\+?[0-9\-]+$/;
  public static AMOUNT_PATTERN = new RegExp(["^[0-9\\d.]*$"].join(""));
  public static PHONE_CODE = /([0-9]{4,})|[1-9]/;
  public static EMAIL_PATTERN = new RegExp(
    ["[a-z|A-Z|0-9]+[@]+[a-z|A-Z|0-9]+[.]+[a-z|A-Z|0-9]+"].join("")
  );
  // public static EMAIL_PATTERN = new RegExp(['/^\S+@\S+\.\S+$/'].join(''));
  public static ZIPCODE_PATTERN = new RegExp(["^[0-9]"].join(""));
  public static DIGIT_PATTERN = new RegExp(["^[\\d]*$"].join(""));
  public static RADIUS_DIGIT_PATTERN = /^[0-9|.|0-9]+$/;
  public static NUMBER_NOT_ZERO = new RegExp(["^[1-9][0-9]*$"].join(""));
  public static FEE_PATTERN = new RegExp(["^\\d+(.\\d{1,2})?$"].join(""));
  public static ALPHA_NUMERIC = new RegExp(["^[A-Za-z0-9]"].join(""));
  public static VALUE_ONE_TO_HUNDRED = new RegExp(
    ["^[1-9][0-9]?$|^100$"].join("")
  );
  public static NON_ZERO_VALUES = new RegExp(["^[1-9][0-9]*$"].join(""));
  public static HOTEL_PROFILE_UPLOAD_ID = "";
  public static HOTEL_PROFILE_UPLOAD_NAME = "";
  public static PERSON_NAME_PATTERN = "^[a-zA-Z][a-zA-Z\\s-_]+$";
  public static COMPANY_AND_PERSON_NAME_PATTERN =
    "^[a-zA-Z0-9][a-zA-Z0-9\\s-_]+$";
  public static FAX_PATTERN = /^\+?[0-9\-]+$/;
  public static TIME_ZONE_FIRST_STRING = /[(]/gi;
  public static TIME_ZONE_SECOND_STRING = /[)]/gi;
  public static url_pattern =
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
  public static DAYS_COLLECTION = [
    { label: "Sun", value: "1" },
    { label: "Mon", value: "2" },
    { label: "Tue", value: "3" },
    { label: "Wed", value: "4" },
    { label: "Thu", value: "5" },
    { label: "Fri", value: "6" },
    { label: "Sat", value: "7" },
  ];

  public static LOGGED_IN_ROLE = "";
  public static roles = [
    {
      roleCode: "System Admin",
      redirect: "/admin-dashboard",
    },
  ];

  public static DATE_FORMATTER = "MMM DD, YYYY hh:mm A";

  public static COOKIE_EXPIRY = 1 * 24 * 60 * 60 * 1000;

  public static FILE_UPLOAD_MAX_SIZE_IN_BYTE = 10000000;

  public static SESSION_KEY_NAME = "X-SESSION-KEY";

  public static OFFICIALS = "office";
  public static EXECUTIVE_BOARD = "board";
  public static COMMITTEE_MEMBERS = "committies";
  public static INVITATION_SENT: "A";
  public static EXPIRED: "E";
  public static COMPLETED: "C";
  public static IN_PROCESS_LOCAL: "L";
  public static IN_PROCESS_WORKER: "W";
  public static IN_PROCESS_ASSOCIATION: "S";

  //Member status
  public static ACTIVE: "A";
  public static PENDING: "P";
  public static TEMPORARY: "T";
  public static TERMINATED: "D";
  public static VERIFIED: "V";

  public static INVITATION_STATUSES = [
    { id: "", value: "All" },
    { id: "A", value: "Invitation Sent" },
    { id: "E", value: "Expired" },
    { id: "C", value: "Completed" },
    { id: "L", value: "In Process(Local)" },
    { id: "W", value: "In Process(Worker)" },
    { id: "S", value: "In Process(Association)" },
    { id: "V", value: "Declined" },

  ];

  public static STATUS = [
    { id: "", value: "All" },
    { id: "A", value: "Active" },
    { id: "I", value: "Inactive" },
  ];

  public static COMPANYSTATUS = [
    { id: "", value: "All" },
    { id: "A", value: "Active" },
    { id: "P", value: "Pending" },
  ];
  public static ADDCOMPANYSTATUS = [
    { id: "A", value: "Active" },
    { id: "P", value: "Pending" },
  ];

  public static district = [
    { id: "", value: "Select District" },
    { id: "ACD", value: "ACD" },
    { id: "SAGCD", value: "SAGCD" },
  ];

  public static APP_OPEN_IN = [
    { id: "I", value: "Within App" },
    { id: "B", value: "External Browser" },
  ];

  public static GENDER_LIST = [
    {
      id: "",
      value: "Select",
    },
    {
      id: "M",
      value: "Male",
    },
    {
      id: "F",
      value: "Female",
    },
  ];

  public static MARITAL_STATUS = [
    {
      id: "",
      value: "Select",
    },
    {
      id: "M",
      value: "Married",
    },
    {
      id: "S",
      value: "Single",
    },
    {
      id: "D",
      value: "Divorced",
    },
    {
      id: "W",
      value: "Widowed",
    },
  ];

  public static YES_NO_LIST = [
    {
      id: null,
      value: "Select",
    },
    {
      id: "Y",
      value: "Yes",
    },
    {
      id: "N",
      value: "No",
    },
  ];

  public static RELATION_LIST = [
    {
      id: "",
      value: "Select",
    },
    {
      id: "Other",
      value: "Other",
    },
    {
      id: "Brother",
      value: "Brother",
    },
    {
      id: "Daughter",
      value: "Daughter",
    },
    {
      id: "Father",
      value: "Father",
    },
    {
      id: "Friend",
      value: "Friend",
    },
    {
      id: "Husband",
      value: "Husband",
    },
    {
      id: "Mother",
      value: "Mother",
    },
    {
      id: "Partner",
      value: "Partner",
    },
    {
      id: "Sister",
      value: "Sister",
    },
    {
      id: "Son",
      value: "Son",
    },
    {
      id: "Wife",
      value: "Wife",
    },
  ];

  public static SUFFIX_LIST = [
    {
      id: "",
      value: "Select",
    },
    {
      id: "Jr.",
      value: "Jr.",
    },
    {
      id: "Sr.",
      value: "Sr.",
    },
    {
      id: "II",
      value: "II",
    },
    {
      id: "III",
      value: "III",
    },
    {
      id: "IV",
      value: "IV",
    },
    {
      id: "V",
      value: "V",
    },
  ];

  public static RACE_LIST = [
    {
      id: "",
      value: "Select",
    },
    {
      id: "Asian-Pacific-Islander",
      value: "Asian/Pacific Islander",
    },
    {
      id: "Black-African-American",
      value: "Black/African American",
    },
    {
      id: "Hispanic-Latino",
      value: "Hispanic/Latino",
    },
    {
      id: "Multiracial-Biracial",
      value: "Multiracial/Biracial",
    },
    {
      id: "Native-American-Alaskan-Native",
      value: "Native American/Alaskan Native",
    },
    {
      id: "Race-Ethnicity-Not-Listed",
      value: "Race/Ethnicity Not Listed",
    },
    {
      id: "White-Caucasian",
      value: "White/Caucasian",
    },
  ];

  public static MEMBERS_STATUS = [
    {
      id: "",
      value: "All",
    },
    {
      id: "A",
      value: "Active",
    },
    {
      id: "P",
      value: "Pending",
    },
    {
      id: "T",
      value: "Temporary",
    },
    {
      id: "D",
      value: "Terminated",
    },
    {
      id: "V",
      value: "Verified",
    },
  ];

  public static USER_TYPES = [
    {
      id: "",
      value: "All",
    },
    {
      id: "A",
      value: "Admin",
    },
    {
      id: "M",
      value: "Member",
    },
  ];
}
