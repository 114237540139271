import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageService } from './storage.service';
import { AppSettings } from './../app.settings';

@Injectable()
export class AuthenticationValidationService implements CanActivate {

  constructor(private storageService: StorageService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const sessionKey = this.storageService.getItemFromCookies(AppSettings.API_ILASESSION);
    const userRole = JSON.parse(
      this.storageService.getItem(AppSettings.USER_ROLE)
    );
    if (this.storageService.getItemFromCookies(AppSettings.API_ILASESSION) && userRole != null) {
      return true;
    }else{
      this.router.navigate(['/signin']);

    }
  }
}
