import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { AppSettings } from "./../../modules/shared/app.settings";
import { UserCommonService } from "./../../modules/shared/services/user-common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { StorageService } from "./../../modules/shared/services/storage.service";
import { CommonMessageTransferService } from "../../modules/shared/services/common-message-transfer.service";
import { Message, MessageService } from "primeng/api";
import { ErrorResponseDto } from "../../modules/shared/models/error-response-dto";
import { ErrorDto } from "../../modules/shared/models/error-dto";
import * as CryptoJS from "crypto-js";
import { UserProfileService } from "../../modules/user-profile/user-profile.service";
import { catchError } from "rxjs/operators";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"],
})
export class SignInComponent implements OnInit, OnDestroy {
  signInResponse: any;
  message: Message[] = [];
  otpId;
  forgotPopup = false;
  signInData: any;
  loginErrors = {
    username: "",
    password: {
      hasError: false,
      error: [],
    },
    general: {
      hasError: false,
      error: [],
    },
  };
  subscription: Subscription;
  isSubmitted = false;
  otpPopup = false;
  setPasswordPopup = false;

  otpEmail;
  otpPhone;
  secretKey = "Mobisoft37";

  signInForm = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl(),
  });

  restAPIFieldSubscription: any;
  restAPIGeneralSubscription: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private signInService: UserCommonService,
    private router: Router,
    private storageService: StorageService,
    private messageService: MessageService,
    private commonMessageTransferService: CommonMessageTransferService,
    private profileDetails: UserProfileService,
    private ms: MessageService
  ) {}

  ngOnInit() {
    // this.restrictBackToPage();
    this.profileApiCallToHandleSingleSignin();
    this.signInForm = this.formBuilder.group({
      password: ["", [Validators.required]],
      email: ["", [Validators.required]],
    });
    this.restAPIFieldSubscription =
      this.commonMessageTransferService.restAPIFieldErrorEvent.subscribe(
        (error: ErrorResponseDto) => {
          console.log("Got field errors", error);
        }
      );

    this.restAPIGeneralSubscription =
      this.commonMessageTransferService.restAPIGeneralErrorEvent.subscribe(
        (error: ErrorDto) => {
          console.log("Got general error", error);
        }
      );

    console.log(this.encrypt("prashant@123"));
    console.log(this.decrypt("U2FsdGVkX18NA/ZJwd6Y/VdK3RoBc2DQ14ZgzO23f7g="));
  }

  onAPIValidation(error) {
    error = JSON.parse(error);
    if (error.general) {
      this.loginErrors.general.hasError = true;
      this.loginErrors.general.error = error.general;
    } else if (error.password) {
      this.loginErrors.password.hasError = true;
      this.loginErrors.password.error = error.password;
    }
  }

  doSignIn() {
    this.signInData = this.signInForm.value;
    this.signInData.validLogin = true;
    this.signInApiCall(this.signInData);
  }

  signInApiCall(signInData) {
    this.signInService
      .signIn("ddd", JSON.stringify(signInData))
      .pipe(
        catchError((err) => {
          this.messageService.add({
            severity: "error",
            summary: err.messages[0].message,
            detail: err.messages[0].message,
          });
          throw err;
        })
      )
      .subscribe((signInResult) => {
        if (signInResult?.messageType === "ERROR") {
          this.messageService.add({
            severity: "error",
            summary: signInResult.messageType,
            detail: signInResult.message,
          });
        } else {
          console.log(signInResult, "signInResult");
          this.signInResponse = signInResult;
          this.setSessionAndUserDetails(signInResult);
        }
      });
  }

  setSessionAndUserDetails(results) {
    // const tokenKey = this.storageService.generateLocalToken(100);
    // if (tokenKey) {
    //   this.storageService.setItemInCookies(
    //     AppSettings.LOCAL_TOKEN_KEY,
    //     tokenKey
    //   );
    // } else {
    //   this.storageService.setItemInCookies(AppSettings.LOCAL_TOKEN_KEY, null);
    // }

    this.storageService.setItem(AppSettings.USER_DETAILS, results.userDetails);
    if (
      results.userDetails.associatedLocals != null &&
      results.userDetails.associatedLocals != undefined
    ) {
      this.storageService.setItem(
        AppSettings.LOCALS,
        results.userDetails.associatedLocals
      );
    }
    this.storageService.setItem(
      AppSettings.ACTIVE_LOCAL,
      results.userDetails.activeLocal
    );
    this.storageService.setItem(
      AppSettings.ONBOARDING_ACCESS,
      results.userDetails.onboardingAccess
    );
    this.storageService.setItem(
      AppSettings.ONBOARDING_WRITE_ACCESS,
      results.userDetails.onboardingWriteAccess
    );
    this.storageService.setItem(
      AppSettings.DISTRICT,
      results.userDetails.district
    );
    this.storageService.setItem(
      AppSettings.USER_ROLE,
      results.userDetails.userRole
    );
    this.storageService.setItem(
      AppSettings.API_SESSION,
      results.userDetails.apiSessionKey
    );

    AppSettings.USER = results.userDetails;
    this.storageService.setItem(
      AppSettings.FIRSTNAME,
      results.userDetails.firstName
    );
    this.storageService.setItem(
      AppSettings.LASTNAME,
      results.userDetails.lastName
    );

    if (
      this.signInResponse.userDetails.userRole === AppSettings.SUPER_ADMIN_ROLE
    ) {
      this.router.navigate(["/app/dashboard"]);
    } else if (
      this.signInResponse.userDetails.userRole === AppSettings.LOCAL_ADMIN_ROLE
    ) {
      this.router.navigate(["/app/members"]);
      if (
        results.userDetails.onboardingAccess ===
        AppSettings.NO_ACCESS_TO_ONBOARDING
      ) {
        this.router.navigate(["/app/all-members"]);
      }
    } else if (
      this.signInResponse.userDetails.userRole ===
        AppSettings.COMPANY_ADMIN_ROLE ||
      this.signInResponse.userDetails.userRole === AppSettings.PVW_ADMIN_ROLE ||
      this.signInResponse.userDetails.userRole ===
        AppSettings.ASSOCIATION_ADMIN_ROLE
    ) {
      this.router.navigate(["/app/all-members"]);
    }
  }

  uniqArray(arrArg) {
    return arrArg.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
  }

  forgotPassword() {
    this.router.navigate(["/forgot-password"]);
  }

  onPopClose(event) {
    this.forgotPopup = false;
  }

  onOtpPopClose(event) {
    this.otpPopup = false;
  }

  onSetPasswordPopClose(event) {
    this.setPasswordPopup = false;
  }

  onOtpPopSave(event) {
    if (event.general !== undefined) {
      this.message = [];
      this.message.push({
        severity: "error",
        summary: "OTP",
        detail: event.general[0].message,
      });
    } else {
      if (event.otpId) {
        this.otpId = event.otpId;
        this.message = [];
        this.message.push({
          severity: "success",
          summary: "OTP Verified",
          detail: "OTP matched.",
        });
        this.setPasswordPopup = true;
      }
    }
    this.forgotPopup = false;
    this.otpPopup = false;
  }

  onPopSave(results) {
    this.otpEmail = results.email;
    this.otpPhone = results.phone;
    this.message = [];
    this.message = [];
    this.message.push({
      severity: "success",
      summary: "Forgot Password.",
      detail: results.general[0].message,
    });
    this.forgotPopup = false;
    this.otpPopup = true;
  }

  eventHandler(event) {
    if (event.keyCode === 13) {
      this.doSignIn();
    }
  }

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(
      CryptoJS.enc.Utf8
    );
  }

  ngOnDestroy() {
    this.restAPIFieldSubscription.unsubscribe();
    this.restAPIGeneralSubscription.unsubscribe();
  }

  // restrictBackToPage() {
  //   if (this.storageService.getItem(AppSettings.API_SESSION)) {
  //     this.router.navigate(["/app/members"]);
  //   } else {
  //     this.router.navigate(["/signin"]);
  //   }
  // }

  profileApiCallToHandleSingleSignin() {
    this.profileDetails.getProfileDetails().subscribe((result) => {
      if (result.messageType === "ERROR") {
        this.router.navigate(["/signin"]);
      } else {
        this.router.navigate(["/app/dashboard"]);
      }
    });
  }
}
