import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password-email-sent',
  templateUrl: './forgot-password-email-sent.component.html',
  styleUrls: ['./forgot-password-email-sent.component.scss']
})
export class ForgotPasswordEmailSentComponent {
  constructor(
    private router: Router
  ) {

  }

  public goToSignIn(): void {
    this.router.navigate(['/signin']);
  }
}
