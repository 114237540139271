import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-p-dialog-delete',
  templateUrl: './p-dialog-delete.component.html',
  styleUrls: ['./p-dialog-delete.component.css']
})
export class PDialogDeleteComponent {
  @Input() showDeleteModal = false;
  @Input() deleteLabel = '';
  @Input() deleteBtnLabel = '';
  @Input() confirmationLabel = '';
  @Output() deleteBtnClicked = new EventEmitter<any>();

  deleteRow(click:any){
    this.deleteBtnClicked.emit(click);

  }
}
