import { Component, Input, OnInit } from "@angular/core";
import { MenuItem, MessageService } from "primeng/api";
import { StorageService } from "./../../services/storage.service";
import { SidebarService } from "./../../services/sidebar.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { UserCommonService } from "../../services/user-common.service";
import { AppSettings } from "../../app.settings";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  subscription: Subscription;
  user;
  ilaLogoClass = "ila-logo-shrink";
  collapsedItems: MenuItem[] = [];
  shrinkItems: MenuItem[] = [];
  userRole: any;
  showLogoutInMenu;
  boolean = false;
  onBoardingProcess: any;
  splittedUrl: any;
  url: any;
  @Input() isCollapsed = false;

  constructor(
    private storageService: StorageService,
    private ms: MessageService,
    private sidebarService: SidebarService,
    private router: Router,
    private userCommonService: UserCommonService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.userRole = JSON.parse(
        this.storageService.getItem(AppSettings.USER_ROLE)
      );
      this.onBoardingProcess = JSON.parse(
        this.storageService.getItem(AppSettings.ONBOARDING_ACCESS)
      );
      if (
        this.onBoardingProcess === AppSettings.NO_ACCESS_TO_ONBOARDING &&
        window.location.hash.substring(2).split("/")[1] === "members"
      ) {
        this.router.navigate(["/app/all-members"]);
      }
      setTimeout(() => {
        this.initSidebarItems();
        this.initShrinkMenuItems();
        this.hambergerHighlits();
      }, 500);
    }, 1000);

    this.showLogoutInMenu = this.collapsedItems.length >= 8;
    this.collapsedItems[this.collapsedItems.length - 1].visible =
      !this.showLogoutInMenu;
    this.shrinkItems[this.shrinkItems.length - 1].visible =
      !this.showLogoutInMenu;
  }

  hambergerHighlits() {
    this.splittedUrl = window.location.hash.substring(2).split("/");
    this.url = this.splittedUrl[1];
    this.collapsedItems.forEach((element) => {
      if (element.routerLink[0] === this.url) {
        element.expanded = true;
        this.setCollapsedMenuActive(element);
      } else {
        element.expanded = false;
      }
    });

    this.shrinkItems.forEach((element) => {
      if (element.routerLink[0] === this.url) {
        element.expanded = true;
        this.setShrinkMenuActive(element);
      } else {
        element.expanded = false;
      }
    });
  }
  public initSidebarItems() {
    this.collapsedItems = [
      {
        routerLink: ["members"],
        icon: "onboarding-members-inactive",
        label: "Onboarding New Workers",
        id: "onboardingMembers",
        visible:this.onBoardingProcess === AppSettings.ACCESS_TO_ONBOARDING,
        command: (event) => {
          this.activateMenu(event);
          this.ilaLogoClass = "ila-logo-shrink";
          event.item.icon = event.item.expanded
            ? "onboarding-members"
            : "onboarding-members-inactive";
        },
      },
      {
        routerLink: ["all-members"],
        icon: "members-inactive",
        label: "Workers",
        id: "members",
        visible:
          this.userRole === "3" ||
          this.userRole === "4" ||
          this.userRole === "5" ||
          this.userRole === "6",
        command: (event) => {
          this.activateMenu(event);
          this.ilaLogoClass = "ila-logo";
          event.item.icon = event.item.expanded
            ? "members"
            : "members-inactive";
        },
      },
      {
        routerLink: ["local-events"],
        icon: "local-events-inactive",
        label: "Local Events",
        id: "localEvents",
        visible: this.userRole === "3",
        command: (event) => {
          this.ilaLogoClass = "ila-logo";
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "local-events"
            : "local-events-inactive";
        },
      },
      {
        routerLink: ["local-announcement"],
        icon: "local-announcment-inactive",
        label: "Local Announcements",
        id: "localAnnouncment",
        visible: this.userRole === "3",
        command: (event) => {
          this.ilaLogoClass = "ila-logo";
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "local-announcment-active"
            : "local-announcment-inactive";
        },
      },
      {
        routerLink: ["local-upcoming-works"],
        icon: "local-upcoming-work-inactive",
        label: "Local Upcoming Work",
        id: "localUpcomingWork",
        visible: this.userRole === "3",
        command: (event) => {
          this.ilaLogoClass = "ila-logo";
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "local-upcoming-work-active"
            : "local-upcoming-work-inactive";
        },
      },
      {
        routerLink: ["officials-committees"],
        icon: "officials-committees-inactive",
        label: "Officials & Committees",
        id: "officialsAndCommittees",
        visible: this.userRole === "3",
        command: (event) => {
          this.ilaLogoClass = "ila-logo";
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "officials-committees-active"
            : "officials-committees-inactive";
        },
      },
      {
        routerLink: ["notification"],
        icon: "notification-inactive",
        label: "Notifications",
        id: "notification",
        visible: this.userRole === "3",
        command: (event) => {
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "notification-active"
            : "notification-inactive";
        },
      },
      {
        routerLink: ["company"],
        icon: "company-inactive",
        label: "Company Information",
        id: "company",
        visible: this.userRole === "3",
        command: (event) => {
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "company-active"
            : "company-inactive";
        },
      },
      // {
      //   icon: "logout",
      //   label: `Logout`,
      //   id: 'logout',
      //   visible: true,
      //   command: () => {
      //     this.logout();
      //   },
      // },
    ];
  }

  //SHRINK MENU
  public initShrinkMenuItems() {
    this.shrinkItems = [
      {
        routerLink: ["members"],
        icon: "onboarding-members-inactive",
        label: null,
        id: "onboardingMembers",
        visible:this.onBoardingProcess === AppSettings.ACCESS_TO_ONBOARDING,
        command: (event) => {
          this.activateMenu(event);
          this.ilaLogoClass = "ila-logo-shrink";
          event.item.icon = event.item.expanded
            ? "onboarding-members"
            : "onboarding-members-inactive";
        },
      },
      {
        routerLink: ["all-members"],
        icon: "members-inactive",
        label: null,
        id: "members",
        expanded: true,
        visible:
          this.userRole === "3" ||
          this.userRole === "4" ||
          this.userRole === "5" ||
          this.userRole === "6",
        command: (event) => {
          this.activateMenu(event);
          this.ilaLogoClass = "ila-logo";
          event.item.icon = event.item.expanded
            ? "members"
            : "members-inactive";
        },
      },
      {
        routerLink: ["local-events"],
        icon: "local-events-inactive",
        label: null,
        id: "localEvents",
        visible: this.userRole === "3",
        command: (event) => {
          this.ilaLogoClass = "ila-logo";
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "local-events"
            : "local-events-inactive";
        },
      },
      {
        routerLink: ["local-announcement"],
        icon: "local-announcment-inactive",
        label: null,
        id: "localAnnouncment",
        visible: this.userRole === "3",
        command: (event) => {
          this.ilaLogoClass = "ila-logo";
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "local-announcment-active"
            : "local-announcment-inactive";
        },
      },
      {
        routerLink: ["local-upcoming-works"],
        icon: "local-upcoming-work-inactive",
        label: null,
        id: "localUpcomingWork",
        visible: this.userRole === "3",
        command: (event) => {
          this.ilaLogoClass = "ila-logo";
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "local-upcoming-work-active"
            : "local-upcoming-work-inactive";
        },
      },
      {
        routerLink: ["officials-committees"],
        icon: "officials-committees-inactive",
        label: null,
        id: "officialsAndCommittees",
        visible: this.userRole === "3",
        command: (event) => {
          this.ilaLogoClass = "ila-logo";
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "officials-committees-active"
            : "officials-committees-inactive";
        },
      },
      {
        routerLink: ["notification"],
        icon: "notification-inactive",
        label: null,
        id: "notification",
        visible: this.userRole === "3",
        command: (event) => {
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "notification-active"
            : "notification-inactive";
        },
      },
      {
        routerLink: ["company"],
        icon: "company-inactive",
        label: null,
        id: "company",
        visible: this.userRole === "3",
        command: (event) => {
          this.activateMenu(event);
          event.item.icon = event.item.expanded
            ? "company-active"
            : "company-inactive";
        },
      },
      // {
      //   icon: "logout",
      //   label: 'Logout',
      //   id: 'logout',
      //   visible: true,
      //   command: () => {
      //     this.logout();
      //   },
      // },
    ];
  }

  public shrinkMenu(): void {
    this.sidebarService.sideSubject.next("shrink-menu");
  }

  public collapseMenu(): void {
    this.sidebarService.sideSubject.next("expand");
  }

  logout() {
    this.userCommonService.logout().subscribe((result) => {
      if (result?.messageType === "ERROR") {
        this.ms.add({
          severity: "error",
          summary: result.messageType,
          detail: result.message,
        });
      } else {
        this.storageService.removeAll();
        this.storageService.removeAllCookies();

        this.ms.add({
          severity: "success",
          summary: result.messageType,
          detail: result.message,
        });
        this.router.navigate(["/signin"]);

      }
    });
  }

  activateMenu(event) {
    this.collapsedItems.forEach((element) => {
      if (element.id === event.item.id) {
        element.expanded = true;
        this.setPreviousMenuInactive(element, "forCollapsedMenu");
      } else {
        element.expanded = false;
      }
    });

    this.shrinkItems.forEach((shrinkMenu) => {
      if (shrinkMenu.id === event.item.id) {
        shrinkMenu.expanded = true;
        this.setShrinkMenuActive(shrinkMenu);
        this.setPreviousMenuInactive(shrinkMenu, "forShrinkedMenu");
      } else {
        shrinkMenu.expanded = false;
      }
    });
  }

  setShrinkMenuActive(shrinkMenu): void {
    this.shrinkItems.forEach((element) => {
      if (shrinkMenu.id === element.id) {
        switch (shrinkMenu.id) {
          case "onboardingMembers":
            element.icon = "onboarding-members";
            break;
          case "members":
            element.icon = "members";
            break;
          case "localEvents":
            element.icon = "local-events";
            break;
          case "localAnnouncment":
            element.icon = "local-announcment-active";
            break;
          case "localUpcomingWork":
            element.icon = "local-upcoming-work-active";
            break;
          case "officialsAndCommittees":
            element.icon = "officials-committees-active";
            break;
          case "notification":
            element.icon = "notification-active";
            break;
          case "company":
            element.icon = "company-active";
            break;
        }
      }
    });
  }

  setCollapsedMenuActive(collapsedMenu): void {
    this.collapsedItems.forEach((element) => {
      if (collapsedMenu.id === element.id) {
        switch (collapsedMenu.id) {
          case "onboardingMembers":
            element.icon = "onboarding-members";
            break;
          case "members":
            element.icon = "members";
            break;
          case "localEvents":
            element.icon = "local-events";
            break;
          case "localAnnouncment":
            element.icon = "local-announcment-active";
            break;
          case "localUpcomingWork":
            element.icon = "local-upcoming-work-active";
            break;
          case "officialsAndCommittees":
            element.icon = "officials-committees-active";
            break;
          case "notification":
            element.icon = "notification-active";
            break;
          case "company":
            element.icon = "company-active";
            break;
        }
      }
    });
  }

  setPreviousMenuInactive(event, forMenu): void {
    if (forMenu === "forCollapsedMenu") {
      this.collapsedItems.forEach((element) => {
        if (event.id !== element.id) {
          switch (element.id) {
            case "onboardingMembers":
              element.icon = "onboarding-members-inactive";
              break;
            case "members":
              element.icon = "members-inactive";
              break;
            case "localEvents":
              element.icon = "local-events-inactive";
              break;
            case "localAnnouncment":
              element.icon = "local-announcment-inactive";
              break;
            case "localUpcomingWork":
              element.icon = "local-upcoming-work-inactive";
              break;
            case "officialsAndCommittees":
              element.icon = "officials-committees-inactive";
              break;
            case "notification":
              element.icon = "notification-inactive";
              break;
            case "company":
              element.icon = "company-inactive";
              break;
          }
        }
      });
    } else {
      this.shrinkItems.forEach((element) => {
        if (event.id !== element.id) {
          switch (element.id) {
            case "onboardingMembers":
              element.icon = "onboarding-members-inactive";
              break;
            case "members":
              element.icon = "members-inactive";
              break;
            case "localEvents":
              element.icon = "local-events-inactive";
              break;
            case "localAnnouncment":
              element.icon = "local-announcment-inactive";
              break;
            case "localUpcomingWork":
              element.icon = "local-upcoming-work-inactive";
              break;
            case "officialsAndCommittees":
              element.icon = "officials-committees-inactive";
              break;
            case "notification":
              element.icon = "notification-inactive";
              break;
            case "company":
              element.icon = "company-inactive";
              break;
          }
        }
      });
    }
  }
}
