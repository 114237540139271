<p-dialog [(visible)]="showDeleteModal" [modal]="true" [style]="{ width: '45vw' }" [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate="header">
    <h2 class="header" translate>{{deleteLabel}}</h2>
  </ng-template>
  <div class="p-col-12">
    <div class="form-label">
      <label translate> label_delete_confirmation</label>
    </div>
  </div>

  <div class="form-buttons p-col-12 padding-buttons">
    <button pButton  type="button" (click)="deleteRow($event)">{{deleteBtnLabel}}</button>
  </div>
</p-dialog>