<div class="company-logo" >
    <img *ngIf="!isCollapsed" class="ila-logo-shrink"  src="/assets/images/ILA-new-logo.png">
    <!-- <div style="background-color: #FFF;">
      <img *ngIf="!isCollapsed" class="ila-logo-shrink"  src="/assets/images/10-active.svg">
    </div> -->

    <img *ngIf="isCollapsed" class="ila-logo-expand"  src="/assets/images/ila-logo.png">
  </div>
  <p-panelMenu [multiple]="true" *ngIf="isCollapsed" class="sidebar-panel-menu" [model]="collapsedItems" >
  </p-panelMenu>
  <p-panelMenu [multiple]="true" *ngIf="!isCollapsed" class="sidebar-panel-menu" [model]="shrinkItems" >
  </p-panelMenu>

  <!-- <div class="logout-container" >
    <div class="logout-icon">
      <img (click)="logout()"  src="/assets/images/logout-icon.png">
    </div>
  </div> -->