import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { AuthenticationValidationService } from './modules/shared/services/authentication-validation.service';
import { LayoutComponent } from './modules/shared/components/layout/layout.component';
import { ForgotPassowrdComponent } from './pages/forgot-passowrd/forgot-passowrd.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ForgotPasswordEmailSentComponent } from './pages/forgot-password-email-sent/forgot-password-email-sent.component';
import { AuthorizationValidationService } from './modules/shared/services/authorization-validation.service';
import { AppSettings } from './modules/shared/app.settings';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';



export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/signin'
  },
  {
    path: 'signin',
    pathMatch: 'full',
    component: SignInComponent
  },

  {
    path: 'forgot-password',
    pathMatch: 'full',
    component: ForgotPassowrdComponent
  },
  {
    path: 'forgot-password-email-sent',
    pathMatch: 'full',
    component: ForgotPasswordEmailSentComponent
  },
  {
    path: 'reset-password',
    pathMatch: 'full',
    component: ResetPasswordComponent
  },
  {
    path: 'signup',
    pathMatch: 'full',
    component: SignUpComponent,
    data: { title: '', breadCrumbs: '' }
  },
  {
    path: 'access-denied',
    pathMatch: 'full',
    component: AccessDeniedComponent,
  },
  {
    path: 'app',
    component: LayoutComponent,
    // canActivate: [AuthenticationValidationService],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(mod => mod.DashboardModule),
        data: { roles: [AppSettings.SUPER_ADMIN_ROLE]
        },
        canActivate: [AuthorizationValidationService]
      },
      {
        path: 'members',
        loadChildren: () => import('./modules/members/members.module').then(mod => mod.MembersModule),
        data: { roles: [AppSettings.LOCAL_ADMIN_ROLE , AppSettings.SUPER_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },
      {
        path: 'all-members',
        loadChildren: () => import('./modules/all-members/all-members.module').then(mod => mod.AllMembersModule),
        canActivate: [AuthorizationValidationService],
        data: { roles: [AppSettings.LOCAL_ADMIN_ROLE, AppSettings.COMPANY_ADMIN_ROLE, AppSettings.ASSOCIATION_ADMIN_ROLE, AppSettings.PVW_ADMIN_ROLE] }
      },
      {
        path: 'local-events',
        loadChildren: () => import('./modules/local-events/local-events.module').then(mod => mod.LocalEventsModule),
        data: { roles: [AppSettings.LOCAL_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },
      {
        path: 'local-announcement',
        loadChildren: () => import('./modules/local-announcement/local-announcement.module').then(mod => mod.LocalAnnouncementModule),
        data: { roles: [AppSettings.LOCAL_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },

      {
        path: 'local-upcoming-works',
        loadChildren: () => import('./modules/local-upcoming-work/local-upcoming-work.module').then(mod => mod.LocalUpcomingWorkModule),
        data: { roles: [AppSettings.LOCAL_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },

      {
        path: 'profile',
        loadChildren: () => import('./modules/user-profile/user-profile.module').then(mod => mod.UserProfileModule),
        data: { roles: [AppSettings.SUPER_ADMIN_ROLE, AppSettings.LOCAL_ADMIN_ROLE, AppSettings.COMPANY_ADMIN_ROLE, AppSettings.ASSOCIATION_ADMIN_ROLE, AppSettings.PVW_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },
      {
        path: 'officials-committees',
        loadChildren: () => import('./modules/officials-committees/officials-committees.module').then(mod => mod.OfficialsCommitteesModule),
        data: { roles: [AppSettings.LOCAL_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },
      {
        path: 'notification',
        loadChildren: () => import('./modules/notifications/notifications.module').then(mod => mod.NotificationsModule),
        data: { roles: [AppSettings.LOCAL_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },
      {
        path: 'company',
        loadChildren: () => import('./modules/company-info/company-info.module').then(mod => mod.CompanyInfoModule),
        data: { roles: [AppSettings.LOCAL_ADMIN_ROLE, AppSettings.SUPER_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },
      {
        path: 'company-admin',
        loadChildren: () => import('./modules/company-admin/company-admin.module').then(mod => mod.CompanyAdminModule),
        data: { roles: [AppSettings.SUPER_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },
      {
        path: 'association-info',
        loadChildren: () => import('./modules/association-info/association-info.module').then(mod => mod.AssociationInfoModule),
        data: { roles: [AppSettings.SUPER_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },
      {
        path: 'pvw-info',
        loadChildren: () => import('./modules/pvw-info/pvw-info.module').then(mod => mod.PvwInfoModule),
        data: { roles: [AppSettings.SUPER_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]

      },
      {
        path: 'association-admin',
        loadChildren: () => import('./modules/association-admin/association-admin.module').then(mod => mod.AssociationAdminModule),
        data: { roles: [AppSettings.SUPER_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },
      {
        path: 'pvw-admin',
        loadChildren: () => import('./modules/pvw-admin/pvw-admin.module').then(mod => mod.PvwAdminModule),
        data: { roles: [AppSettings.SUPER_ADMIN_ROLE] },
        canActivate: [AuthorizationValidationService]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
