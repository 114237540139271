import { Injectable } from "@angular/core";
import { TranslateLoader } from "@ngx-translate/core";
import { of, Observable } from "rxjs";

@Injectable()
export class MiTranslateLoaderService implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of({
      Email: "Email",
      Username: "Username",
      Password: "Password",
      "Username is should be greater than x":
        "Username is should be greater than {{minval}}",
      forgot_password: "Forgot Password?",

      grid_title: "TITLE",
      grid_trial_start_date: "START DATE",
      grid_trail_end_date: "END DATE",
      grid_patients: "PATIENTS",
      lbl_no_records_found: "No records found",

      sidebar_lbl_customers: "Customers",
      sidebar_lbl_merchants: "Merchants",
      sidebar_lbl_auctions: "Auctions",
      sidebar_lbl_support: "Support",
      sidebar_lbl_vaquitas: "Vaquitas",
      sidebar_lbl_notifications: "Notifications",
      sidebar_lbl_admin_users: "Admin users",
      sidebar_lbl_settings: "Settings",
      form_download_msg:'There is no form available to download.',
      msg_logout: "Are you sure that you want to perform this action?",
      lbl_confirm_idle_session: `Sesion has been idle over its time limit,
      It will be disconnected in 2 minutes. Press any key now to continue session.`,
      username_required: 'Username is required',
      please_enter_you_email_address_to_get_instructions: 'Please enter your email address to get instructions.',
      email_required: 'Email is required',
      err_invalid_email: 'Invalid email',
      err_enter_valid_email: 'Please enter valid email address',

      lbl_new_applicant: 'New Applicant',
      worker_id: 'Worker ID',
      lbl_work: 'Work#',
      worker: 'Worker #',
      lbl_local_id: 'Local ID',
      lbl_name: 'Name',
      lbl_last_name: 'Last Name',
      lbl_email: 'Email',
      lbl_phone: 'Phone',
      lbl_date_send_on: 'Date Send On',
      lbl_invitation_sent: 'INVITATION SENT',
      lbl_status: 'Status',
      lbl_action: 'Action',
      workerId_required: 'Worker Id is required',
      enter_your_password_change_it_and_relogin: 'Enter your new password, change it and re login.',
      set_new_password: 'Set New Password',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
      new_password_required: 'New password is required.',
      confirm_password_required: 'Confirm password is required',
      err_password_confirm_password_match: 'New password and Confirm new password does not match.',
      thank_you: 'Thank You!',
      forgot_password_email_sent_success_msg: 'You should receive an email with a link to reset your password.',
      lbl_event_date: 'Event Date',
      lbl_title: 'Title',
      lbl_description: 'Description',
      lbl_updated_on: 'Updated On',
      lbl_district: 'District',
      lbl_event_date_range: 'Event Date Range',
      lbl_updated_on_range: 'Updated On Range',
      lbl_local_events: 'Local Events',
      lbl_local_id_required: 'Local id is required',
      label_venue: 'Venue',
      label_start_date: 'Start Date',
      label_end_date: 'End Date',
      label_address: 'Address',
      label_start_time: 'Start Time',
      label_end_time: 'End Time',
      label_source_link: 'Source Link',
      label_title: 'Title',
      label_limit_100_characters: '(Limit 100 characters)',
      label_limit_2000_characters: '(Limit 2000 characters)',
      label_limit_1500_characters: '(Limit 1500 characters)',
      label_openIn: 'Open In',
      label_description: 'Description',
      err_start_date_required: 'Start Date is required',
      err_end_date_required: 'End Date is required',
      start_time_required: 'Start Time is required',
      end_time_required: 'End Time is required',
      title_required: 'Title is required',
      open_in_required: 'Open in required',
      description_required: 'Descriptin is required',
      err_status_required: 'Status is required',
      label_status: 'Status',
      label_active: 'Active',
      label_inactive: 'Inactive',
      lbl_push_notification: 'Push Notification',
      label_add_local_event: 'Add Local Event',
      lbl_do_you_really_want_to_delete_event: 'Do you really want to delete event?',
      lbl_delete_event: 'Delete Event',
      lbl_delete_announcement : "Delete Announcement",
      lbl_delete_upcoming_work: 'Delete Upcoming Work',
      lbl_delete_notification: 'Delete Notification',
      lbl_are_you_sure_to_change_district:'Are You Sure You Want To Change District',

      lbl_announcement: "Announcement",
      lbl_url: "URL",
      lbl_send_docusign_envelope: "Send Docusign Envelope",
      lbl_invite_new_applicant: "Invite New Applicant",
      lbl_docusign_applicant_msg:
        "A Docusign envelope will be sent to new hire/applicant to complete the onboarding process.",
      lbl_first_name: "First Name",
      lbl_name_required: "Name is required",
      lbl_last_name_required: "Last Name is required",
      lbl_first_name_required: "First Name is required",
      lbl_phone_no: "Phone Number",
      lbl_phone_no_required: "Phone No is required",
      lbl_send_invite: "Send Invite",
      lbl_send_invite_by_email: "Send invite by Email",
      lbl_send_invite_by_sms: "Send invite by SMS",
      lbl_send_invite_by_office: "Send invite by Office",
      lbl_resend_invite_by_email: "Resend invite by Email",
      lbl_resend_invite_by_sms: "Resend invite by SMS",
      lbl_resend_invite_by_office: "Resend invite by Office",
      lbl_phone_number_length: 'Phone Number should be 10 digit',
      lbl_clear_all: 'Clear All',
      lbl_items_per_page:'Items per page',
      lbl_local_announcement: 'Local Announcement',
      lbl_add_local_announcement: 'Add Local Announcement',
      lbl_url_Message: 'URL(This is where you can enter a link)',
      lbl_active: 'Active',
      lbl_inactive: 'Inactive',
      lbl_announcement_required: 'Announcement is required',
      lbl_please_enter_valid_url: 'please enter valid URL',
      label_delete_confirmation: 'Do you really want to delete ?',
      lbl_district_conform_company_msg:'Please note that any previously selected options for locals, association and PV&W will be reset.',
      lbl_district_conform_association_msg:'Please note that any previously selected options for locals, company and PV&W will be reset.',
      lbl_district_conform_pvw_msg:'Please note that any previously selected options for locals, company and association will be reset.',

      label_delete_member_confirmation: 'Do you really want to delete Worker?',
      label_edit_confirmation: 'Do you really want to edit event?',
      label_activate_event: 'Activate Event',
      label_deactivate_event: 'Deactivate Event',
      label_do_you_really_want_to: 'Do you really want to',
      label_activate: 'Activate',
      label_deactivate: 'Deactivate',
      label_edit_local_event: 'Edit Local Event',
      err_start_time_greater_than_end_time: 'Start time is greater than End time',
      lbl_Local_Upcoming_Work: 'Local Upcoming Work',
      lbl_update_Local_Upcoming_Work: 'Update Local Upcoming Work',
      lbl_Work_Date: 'Work Date',
      lbl_Date_Posted: 'Date Posted',
      lbl_Announcement_is_required: 'Announcement is required',
      lbl_Url_is_required: 'Url is required',
      lbl_GANGS: 'GANGS',
      lbl_JOBS: 'JOBS',
      lbl_DESCRIPTION: 'DESCRIPTION',
      lbl_Add_Local_Upcoming_Work: 'Add Local Upcoming Work',
      lbl_No_Gangs: 'No. of Gangs',
      lbl_No_Jobs: 'No. of Jobs',
      err_title_should_not_greater_than_hundred: 'Event title should not be greater than 100 characters.',
      err_description_should_not_greater_than_two_thousand: 'Event description should not be greater than 2000 characters.',
      lbl_officials_committees: 'Officials & Committees',
      lbl_workerId: 'WorkerId/Work#',
      lbl_workerId2: 'WorkerId',
      lbl_firstName: "First Name",
      lbl_lastName: 'Last Name',
      err_first_name_required: 'First name is required',
      err_last_name_required: 'Last name is required',
      lbl_cell_phone: 'Cell Phone',
      lbl_office_phone: 'Office Phone',
      lbl_add_new_official: 'Add New Official',
      lbl_title_required: 'Title is required',
      lbl_edit_event: 'Edit Event',
      err_cell_phone_is_required: 'Cell Phone is required',
      lbl_old_password_is_required: 'Old password is required',
      lbl_new_password_is_required: 'New password is required',
      lbl_Confirm_password_is_required: 'Confirm password is required',
      lbl_new_password_did_not_match: 'New password did not match',
      lbl_invitation_sent_small: 'Invitation Sent',
      lbl_days: 'Days',
      lbl_create_invitation: 'Create Invitation',
      lbl_send_invite_through: 'Send Invite Through',
      lbl_notifications: "Notifications",
      label_photo: 'Photo',
      lbl_sr_no:'SR. No.',
      lbl_message:'Message',
      lbl_device_type:'Device Type',
      lbl_local:'Local',
      lbl_created_on:'Created ON',
       lbl_create_notificarion:'Create Notification',
       lbl_zip_code:'ZipCode',
       lbl_country:'Country',
       lbl_state:'State',
       lbl_county:'County',
       lbl_city:'City',
       lbl_home_local:'Home Local',
       lbl_user_type:'User Type',
       lbl_push_to_page:'Push To Page',
       lbl_search_tag:'Search Tag',
       lbl_members:'Workers',
       lbl_members_details: 'Workers Details',
       lbl_mesaage_label:'Message(English)(Limit 1000 characters)',
       lbl_select_date:'Select Date',
       lbl_in_office:'In Office',
       lbl_text:'Text',
       lbl_update_local_announcement:'Update Local Announcement',
       lbl_event:'event',
       lbl_delete_worker: 'Delete Onboarding Invite',
       lbl_are_you_sure_you_want_to_delete_worker: 'Are you sure you want to delete Onboarding invite for this worker?',
       lbl_cancel:'Cancel',
       lbl_delete: 'Delete',
       lbl_your_photo:'Your Photo',
       lbl_your_details :'Your Details',
       lbl_change_image:'Change Image',
       lbl_upload:'Upload',
       lbl_company_information:'Company Information',
       lbl_change_password:'Change Password',
       lbl_old_password:'Old Password',
       lbl_password_length:'Password should be minimum 6 digit',
       lbl_confirm_password:'Confirm Password',
       lbl_delete_member: 'Delete Worker',
       lbl_member: 'Worker',
       err_please_enter_10_digit_phone: 'Please enter 10 digit valid phone number.',
       lbl_view_notifications:'View Notifications',
       lbl_port:'Port',
       lbl_company_id:'Company Id',
       lbl_company_name:'Company Name',
       lbl_association_name:'Association Name',
       lbl_pvw_name:'PV&W Name',
       lbl_add_company:'Add Company',
       lbl_add_association:'Add Association',
       lbl_add_pvw:'Add PV&W',

       lbl_edit_association:'Edit Association',
       lbl_edit_pvw:'Edit PV&W',

       lbl_edit_company:'Edit Company',
       lbl_view_company:'View Company',
       lbl_contact_name:'Contact Name',
       lbl_select_port:'Select Port',
       lbl_select_locals:'Select Locals',
       lbl_locals:'Locals',

       lbl_association:'Association',
       lbl_association_info:'Association Information',

       lbl_pv_and_w:'PV&W',
       lbl_pv_and_w_info:'PV&W Information',
       lbl_sms:'SMS',
       err_please_enter_valid_zip_code: 'Please enter valid zip code',
       err_zip_code_is_required: 'Zip code is required',
       err_state_required: 'State is required.',
       lbl_user_types: 'User Types',
      lbl_user_status: 'User Status',
      lbl_ssn: 'SSN',
      lbl_worker_information: 'Worker Information',
      lbl_member_id: 'Worker ID',
      lbl_contact_information: 'Contact Information',
      lbl_emergency_contact: 'Emergency Contact',
      lbl_current_address: 'Current Address',
      lbl_middleName: 'Middle Name',
      lbl_suffix: 'Suffix',
      lbl_date_of_birth: 'Date Of Birth',
      lbl_gender_sex: 'Gender/Sex',
      lbl_marital_status: 'Marital Status',
      lbl_race_ethinicity: 'Race Ethnicity',
      lbl_cellPhone: 'Cell Phone',
      lbl_home_phone: 'Home Phone',
      lbl_email_address: 'Email Address',
      lbl_relationship: 'Relationship',
      lbl_address: 'Address',
      lbl_documents: 'Documents',
      lbl_type: 'Type',
      lbl_upload_date: 'Upload Date',
      err_date_of_birth_required: 'Date of birth is required.',
      err_gender_required: 'Gender is required.',
      err_cell_phone_required: 'Cell phone is required.',
      err_emergency_name_required: 'Emergency name is required.',
      err_emergency_relationship_required: 'Emergency relationship is required.',
      lbl_edit_member: 'Edit Worker',
      lbl_view_member: 'View Worker',

      lbl_company_admin: 'Company Admin',
      lbl_company:'Company',
      lbl_created_on_date_range: 'Created On Date Range',
      lbl_manage_company_admin: 'Manage Company Admin',
      lbl_add_company_admin: 'Add Company Admin',
      lbl_edit_company_admin: 'Edit Company Admin',
      lbl_company_name_is_required: 'Company Name is required',
      err_city_is_required: 'City is required',
      err_state_is_required: 'State is required',
      err_zipcode_is_required: 'Zip code is required',
      err_country_is_required: 'Country is required',
      err_county_is_required: 'County is required',
      err_address_required: 'Address is required',
      lbl_delete_company_admin: 'Delete Company Admin',
      lbl_delete_association_admin: 'Delete Association Admin',
      lbl_delete_pvw_admin: 'Delete PV&W Admin',
      lbl_are_you_sure_you_want_to_delete_company_admin: 'Are you sure you want to delete Company Admin?',
      lbl_are_you_sure_you_want_to_delete_association_admin: 'Are you sure you want to delete Association Admin?',
      lbl_are_you_sure_you_want_to_delete_pvw_admin: 'Are you sure you want to delete PV&W Admin?',
      err_while_deleteting_company_admin: 'Error while deleting company admin',
      err_while_deleteting_pvw_admin: 'Error while deleting pv&w admin',
      err_while_deleteting_association_admin: 'Error while deleting association admin',
      successfully_deleted_company_admin: 'Successfully deleted company admin',
      successfully_deleted_association_admin: 'Successfully deleted association admin',
      successfully_deleted_pvw_admin: 'Successfully deleted pvw admin',
      err_while_changing_company_admin_status: 'Error while changing company admin status',
      err_while_changing_association_admin_status: 'Error while changing association admin status',
      err_while_changing_pvw_admin_status: 'Error while changing pvw admin status',
      successfully_updated_company_admin_status: 'Successfully updated company admin status',
      successfully_updated_association_admin_status: 'Successfully updated association admin status',
      successfully_updated_pvw_admin_status: 'Successfully updated pvw admin status',
      lbl_manage_association_admin: 'Manage Association Admin',
      lbl_manage_pvw_admin: 'Manage PV&W Admin',
      lbl_add_association_admin: 'Add Association Admin',
      lbl_add_pvw_admin: 'Add PV&W Admin',
      lbl_edit_association_admin: 'Edit Association Admin',
      lbl_edit_pvw_admin: 'Edit PV&W Admin',
      lbl_association_admin: 'Association Admin',
      lbl_pvw_admin: 'PV&W Admin',
      label_add_new_tag: 'Add New Tag',
      label_remove_image: 'Remove Image',
      lbl_remove_image: 'remove image',
      lbl_committee: 'Committee',
      lbl_dashboard: 'Dashboard',
      lbl_number_of_users: 'Number of Users',
      lbl_number_of_news: 'Number of News',
      lbl_number_of_media_images: 'Number of Media Images',
      lbl_number_of_mobile_videos: 'Number of Mobile Videos',
      lbl_safty_training_publications: 'Safty Training Publications',
      lbl_safty_training_videos: 'Safty Taining Videos',
      lbl_address_line_one: 'Address Line 1',
      lbl_address_line_two: 'Address Line 2',
      lbl_your_profile : 'Your Profile',
      lbl_other_local:'Other Local',
      lbl_universal_id:'ILA Universal ID',
      lbl_upload_image:'Upload Image',
      lbl_tags:'Tags'
    });
  }
}
