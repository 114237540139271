import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

import { UserCommonService } from '../../modules/shared/services/user-common.service';
import { AppSettings } from '../../modules/shared/app.settings';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';


@Component({
  selector: 'app-forgot-passowrd',
  templateUrl: './forgot-passowrd.component.html',
  styleUrls: ['./forgot-passowrd.component.scss']
})
export class ForgotPassowrdComponent {
  forgotPasswordResponse: any;
  subscription: Subscription;
  isSubmitted = false;
  otpPopup = false;
  setPasswordPopup = false;
  otpEmail;
  otpPhone;
  signInForm = new UntypedFormGroup({
    email: new UntypedFormControl()
  });
  restAPIFieldSubscription: any;
  restAPIGeneralSubscription: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userCommonService: UserCommonService,
    private messageService: MessageService,
    private commonBindingService: CommonBindingDataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.signInForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]],
    });
  }

  public forgotPassword(): void {
    const forgotPasswordRequest = this.signInForm.value;
    this.userCommonService.forgotPassword(forgotPasswordRequest)
    .pipe(catchError(err => {
      this.messageService.add({
        severity: 'error',
        summary: err.messages[0].message,
        detail: err.messages[0].message
      });
      throw err;
    }))
    .subscribe(result => {
      if (result?.messageType === 'ERROR') {
        this.messageService.add({
          severity: 'error',
          summary: result.messageType,
          detail: result.message
        });
      } else {
        this.messageService.add(
          {
            severity: 'success',
            summary: result.messageType,
            detail: this.commonBindingService.getLabel('forgot_password_email_sent_success_msg')
        });
        setTimeout(() => {
          this.router.navigate(['/forgot-password-email-sent']);
        }, 500);
      }
    })
  }
}