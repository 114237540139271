
  <div class="company-logo" >
    <img *ngIf="!isCollapsed" class="ila-logo-shrink"  src="/assets/images/ILA-new-logo.png">
    <img *ngIf="isCollapsed" class="ila-logo-expand"  src="/assets/images/ila-logo.png">
  </div>
  <p-panelMenu *ngIf="isCollapsed" class="sidebar-panel-menu"  [model]="collapsedItems" >
  </p-panelMenu>
  <p-panelMenu *ngIf="!isCollapsed" class="sidebar-panel-menu"  [model]="shrinkItems" >
  </p-panelMenu>

  <!-- <div class="logout-container" >
    <div class="logout-icon">
      <img (click)="logout()"  src="/assets/images/logout-icon.png">
    </div>
  </div> -->
    <div [ngClass]="isCollapsed ? 'logout-topborder-expanded' : 'logout-topborder-shrinked'" class="logout-button" (click)="logout()">
      <img  [ngClass]="isCollapsed ? 'logout-img-expanded' : 'logout-img-shrinked'"  src="/assets/images/logout-icon.png"><span *ngIf="isCollapsed" class="logout-text">Logout</span>
    </div>


