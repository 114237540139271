import { NavtabComponent } from './components/navtab/navtab.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
  TranslatePipe,
  TranslateDirective,
  MissingTranslationHandler
} from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CheckboxModule}from 'primeng/checkbox';
  import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {PanelMenuModule} from 'primeng/panelmenu';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {TabViewModule} from 'primeng/tabview';
import {TabMenuModule} from 'primeng/tabmenu';
import {BlockUIModule} from 'primeng/blockui';
import {RatingModule}  from 'primeng/rating';
import {ButtonModule} from 'primeng/button';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ChartModule} from 'primeng/chart';
import {MenuModule} from 'primeng/menu';
import {CalendarModule} from 'primeng/calendar';
import {DialogModule} from 'primeng/dialog';
import {EditorModule} from 'primeng/editor';
import {PanelModule} from 'primeng/panel';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {FileUploadModule} from 'primeng/fileupload';
import {ConfirmationService} from 'primeng/api';

import { MiGridComponent } from './components/mi-grid/mi-grid.component';
import { GridService } from './components/mi-grid/grid.service';
import { UiService } from './services/ui.service';
import { RestApiService } from './services/rest-api.service';
import { UserCommonService } from './services/user-common.service';
import { StorageService } from './services/storage.service';
import { CommonMessageTransferService } from './services/common-message-transfer.service';
import { CommonBindingDataService } from './services/common-binding-data.service';
import { SidebarService } from './services/sidebar.service';
import { AuthenticationValidationService } from './services/authentication-validation.service';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { MiTranslateLoaderService } from './services/mi-translate-loader.service';
import { MiMissingTranslationHandlerService } from './services/mi-missing-translation-handler.service';
import { AssignSearchComponent } from './components/assign-search/assign-search.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { LoaderService } from './components/loader/loader.service';
import { LoaderComponent } from './components/loader/loader.component';
import { FileUploaderComponent } from './components/file-uploader/file.uploader.component';
import { AgoPipe } from './pipes/ago.pipe';
import { DateFormatPipe } from './pipes/dateformat.pipe';
import { DateTimeFormatPipe } from './pipes/datetimeformat.pipe';
import { MinutsFormatPipe } from './pipes/minutesformat.pipe';
import { SearchLocationDirective } from './directives/search-location.directive';
import { DisableControlDirective } from './directives/input-disabled.directive';
import { LayoutComponent } from './components/layout/layout.component';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { PDialogDeleteComponent } from './components/p-dialog-delete/p-dialog-delete.component';
import { SuperAdminSidebarComponent } from './components/super-admin-sidebar/super-admin-sidebar.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PanelMenuModule,
    RatingModule,
    ButtonModule,
    EditorModule,
    ConfirmDialogModule,
    CalendarModule,
    ChartModule,
    MenuModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    DialogModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    BlockUIModule,
    OverlayPanelModule,
    PanelModule,
    TabMenuModule,
    TabViewModule,
    TableModule,
    ToastModule,
    SidebarModule,
    DialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: MiTranslateLoaderService
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MiMissingTranslationHandlerService
      }
    })
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    DisableControlDirective,
    SearchLocationDirective,
    AssignSearchComponent,
    PieChartComponent,
    BarChartComponent,
    LoaderComponent,
    FileUploaderComponent,
    AgoPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    MinutsFormatPipe,
    NavtabComponent,
    MiGridComponent,
    LayoutComponent,
    PDialogDeleteComponent,
    SuperAdminSidebarComponent

  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    TranslateModule,
    ConfirmDialogModule,
    SearchLocationDirective,
    DisableControlDirective,
    AssignSearchComponent,
    PieChartComponent,
    BarChartComponent,
    LoaderComponent,
    FileUploaderComponent,
    AgoPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    MinutsFormatPipe,
    NavtabComponent,
    MiGridComponent,
    PDialogDeleteComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        LoaderService,
        RestApiService,
        UiService,
        UserCommonService,
        StorageService,
        CommonMessageTransferService,
        CommonBindingDataService,
        SearchLocationDirective,
        DisableControlDirective,
        AuthenticationValidationService,
        ConfirmationService,
        TranslateService,
        SidebarService,
        GridService
      ]
    };
  }

  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }
}
