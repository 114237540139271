import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { MessageService } from "primeng/api";

import { UiService } from "./../../services/ui.service";
import { GridService } from "./grid.service";
import { GridModel } from "./grid-model";
import { LoaderService } from "../loader/loader.service";
import { LazyLoadEvent } from "primeng/api";
import { Router } from "@angular/router";
import { UserCommonService } from "../../services/user-common.service";
import { Table } from "primeng/table";
@Component({
  selector: "app-mi-grid-v2",
  providers: [GridService],
  templateUrl: "./mi-grid.component.html",
  styleUrls: ["./mi-grid.component.scss"],
})
export class MiGridComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  rowData: any[] = [];
  @Input() search = "";
  gridUrlParams = "";
  @Output() rowClickedEvent = new EventEmitter<any>();
  @Output() actionBtnClickedEvent = new EventEmitter<any>();
  @Output() getGridReference: EventEmitter<any> = new EventEmitter();
  @Output() clearAllEvent = new EventEmitter<any>();
  @Output() isrecord = new EventEmitter<any>();
  @Input() url: any = "";
  @Input() searchBar: string;
  @Input() exportButton: string;
  @Input() rowSelection = "single";
  @Input() printUrl: any = "";
  @Input() exportUrl: any = "";
  @Input() columnDefs: any[] = [];
  @Input() actionButtonsDefs: any[] = [];
  @Input() pagination: any = true;
  @Input() paginationPageSize: any = 10;
  @Input() paginationStartPage: any = 0;
  @Input() defaultSortField = "";
  @Input() divClass = "";
  @Input() optionsForDropDownOne: any[] = [];
  @Input() selectedStatus = "A";
  @Input() buttonLabel: string = null;
  @Input() searchbarClass = "p-col-3";
  @Input() statusClass = "p-col-3";
  @Input() actionButtonClass = "p-col-3";
  @Input() clearButtonClass = "p-col-3";
  @Input() rowsPerPage = [10, 25, 50];
  @Input() isStatusDropdown = false;
  @Input() isAddMember = false;
  @Input() isClear = false;
  @Input() showExportBtn = false;
  @Input() exportButtonClass = "p-col-4";
  @Input() searchPlaceholder = "Search";
  @Input() exportBtnLabel = "Export To Excel";

  @Input() showActionBtn = true;
  @Input() showSearchAndActionBtn = true;
  @Output() exportBtnClicked = new EventEmitter<any>();
  @Output() emitTotalRecords = new EventEmitter<any>();

  enableResizer = "false";
  @Input() overlayLoadingTemplate: any =
    '<span class="ag-overlay-loading-center" "style=background:red">' +
    "Please wait while your rows are loading</span>";
  datasource = [];
  model: GridModel;
  totalRecords = 0;
  sortF = "";
  sortO = "";
  offset = 0;
  setRowLength = 10;
  searchText = "";
  @Output() searchEnterText = new EventEmitter<any>();
  @Input() status = "A";
  selectedRecords: any = [];
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;
  @ViewChild("resetPage", { static: true }) resetPage: Table;

  constructor(
    private elm: ElementRef,
    private loaderService: LoaderService,
    private uiService: UiService,
    private gridService: GridService,
    private router: Router,
    private commonService: UserCommonService,
    private ms: MessageService
  ) {}

  ngOnInit() {
    this.subscription = this.uiService.sidebarToggledEvent.subscribe(
      (state: string) => {
        this.refreshList();
      }
    );
  }

  onResize(event: any) {
    this.refreshList();
  }

  refreshList() {
    this.fetchAndLoadData();
  }

  fetchAndLoadData() {
    //  const that = this;
    let params =
      "search=" +
      this.searchText +
      "&recordStatus=" +
      this.status +
      "&offset=" +
      this.offset +
      "&limit=" +
      this.setRowLength;

    params += "&sortColumn=";
    params += this.sortF;
    params += "&sortType=";
    params += this.sortO;
    let buildUrl = this.url;
    if (this.url.lastIndexOf("?") < 0) {
      buildUrl += "?";
    } else {
      buildUrl += "&";
    }
    buildUrl += params;
    this.gridService.loadGridData(buildUrl).subscribe((result) => {
      this.isrecord.emit(result.records.length);
      this.loaderService.hide();
      const model = <GridModel>result;
      if (result !== undefined) {
        this.totalRecords = model.pagination.totalRecords;
        this.emitTotalRecords.emit(this.totalRecords);
        this.datasource = [];
        model.records.forEach((e) => {
          this.columnDefs.forEach((c) => {
            if (c && c.cellRenderer && !c.htmlRenderer) {
              e[c.field] = c.cellRenderer(e[c.field]);
            }
            if (c && c.htmlRenderer) {
              e[c.field] = c.htmlRenderer(e);
            }
          });

          this.datasource.push(e);
        });
      }
    });
  }

  public onQuickFilterChanged($event) {
      this.applySearch(this.search);
  }

  updateUrl(url: string) {
    this.url = url;
    this.refreshList();
  }

  loadDatasourceLazy(event: LazyLoadEvent) {
    this.sortF = this.defaultSortField;
    this.offset = event.first;
    this.setRowLength = event.rows;
    if (event.sortField !== undefined) {
      this.sortF = event.sortField;
    }
    this.sortO = event.sortOrder > 0 ? "asc" : "desc";
    this.fetchAndLoadData();
  }

  onRowSelect(event) {
    this.rowClickedEvent.emit(event);
  }

  onRowUnselect(event) {
    this.rowClickedEvent.emit(event);

  }

  public showSendEmailPopup(): void {}

  actionBtnClick(data: any, action: string) {
    const obj = { row: data, class: action };
    this.actionBtnClickedEvent.emit(obj);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getSelectedRecords() {
    return this.selectedRecords;
  }

  applySearch(searchText: string) {
    this.searchEnterText.emit(this.searchText);
    this.offset = 0;
    this.searchText = searchText;
    this.fetchAndLoadData();
    if (this.searchText === "") {
      this.resetPage.reset();
    }
  }

  statusFilter(event: any) {
    this.status = event.value;
    this.fetchAndLoadData();
  }

  exportGrid(event: any) {
    this.exportBtnClicked.emit();
  }

  clearAll(): void {
    this.searchText = "";
    this.search = null;
    this.status = "";
    this.clearAllEvent.emit(true);
    this.fetchAndLoadData();
  }

  // exportGridExcel(event: any) {

  //   const that = this;

  //   let buildUrl = that.fetchAndLoadData(that.exportUrl);

  //   const tempUrl = that.fetchAndLoadData(that.exportUrl);

  //   if (tempUrl.lastIndexOf('?') < 0) {

  //     buildUrl += '?' + this.gridUrlParams;

  //   } else {

  //     buildUrl += '&' + this.gridUrlParams;

  //   }

  //   this.gridService.downloadExcelGridData(buildUrl);

  // }

  // public downloadExcelGridData(url: string) {

  //   return this.restApiService.excel(url, 'export.xls', 'page-center');

  // }
}
