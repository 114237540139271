<div class="p-grid align-items-center vertical-container full-height">
    <div class="p-col-5 p-col-nogutter">
      <div class="full-height left-panel">
      <div class="login-form">
        <img class="ila-image" src="../../../assets/images/union-connect-logo@2x.png">
        <div class="p-col-12 p-col-nogutter">
          <label class="label-welcome-back">Have you forgotten</label>
        </div>
        <div class="p-col-12 p-col-nogutter">
          <img class="password-image" src="../../../assets/images/passwordImage.png">
        </div>
        <div class="p-col-12 p-col-nogutter instruction-div">
           <label class="instructions" translate>please_enter_you_email_address_to_get_instructions</label>
        </div>

        <form [formGroup]="signInForm">
          <div class="form-container p-grid">
            <div class="form-field p-col-9">
              <div class="form-label">
                <label class="lbl-usrname" for="email" translate>Email</label>
              </div>
              <div class="form-control">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                      <img class="user-icon" src="../../../assets/images/user.png">
                  </span>
                  <input
                    pInputText
                    formControlName="email"
                  />
                </div>
              </div>
              <div
                class="form-errors"
                *ngIf="signInForm.controls.email.errors && (signInForm.controls.email.dirty || signInForm.controls.email.touched)"
              >
                <div
                  [hidden]="!signInForm.controls.email.errors.required"
                  translate
                >
                  email_required
                </div>
                <div *ngIf="signInForm.controls.email.errors.pattern" translate>
                  err_invalid_email
                </div>
              </div>
            </div>

            <div class="form-buttons p-col-9">
              <button
                pButton
                label="Submit"
                type="button"
                class="sign-in-button"
                [disabled]="!signInForm.valid"
                (click)="forgotPassword()"
              ></button>
            </div>
          </div>
        </form>
      </div>
      </div>
    </div>
    <div class="p-col-7 p-col-nogutter right-panel-div">
      <img class="right-panel-image"  src="../../../assets/images/group-92.png">
    </div>
  </div>

  <p-toast></p-toast>