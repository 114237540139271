<div class="p-grid align-items-center vertical-container full-height">
  <div class="p-col-5 p-col-nogutter">
    <div class="full-height left-panel">
      <div class="login-form">
        <img class="ila-image" src="../../../assets/images/union-connect-logo@2x.png">
        <div class="p-col-12 p-col-nogutter">
          <label class="label-welcome-back" translate>set_new_password</label>
        </div>
        <div class="p-col-12 p-col-nogutter instruction-div">
          <label class="instructions" translate>enter_your_password_change_it_and_relogin</label>
        </div>
        <form [formGroup]="resetPasswordForm">
          <div class="form-container p-grid">
            <div class="form-field p-col-9 margin-bottom">
              <div class="form-label">
                <label class="lbl-usrname" for="newPassword" translate>new_password</label>
              </div>
              <div class="form-control">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <img
                      class="key-icon"
                      src="../../../assets/images/key.png"/>
                  </span>
                  <p-password
                    formControlName="newPassword"
                    [feedback]="false"
                    toggleMask="true"
                  ></p-password>
                </div>
              </div>
              <div
                class="form-errors"
                *ngIf="resetPasswordForm.controls.newPassword.errors && (resetPasswordForm.controls.newPassword.dirty || resetPasswordForm.controls.newPassword.touched)">
                <div
                  [hidden]="!resetPasswordForm.controls.newPassword.errors.required" translate>new_password_required
                </div>
                <div *ngIf="resetPasswordForm.controls.newPassword.errors.pattern" translate>
                    err_invalid_email
                </div>
              </div>
            </div>

            <div class="form-field p-col-9 margin-bottom">
              <div class="form-label">
                <label class="lbl-usrname" for="confirmNewPassword" translate>confirm_new_password</label>
              </div>
              <div class="form-control">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <img
                      class="key-icon"
                      src="../../../assets/images/key.png"
                    />
                  </span>
                  <p-password
                    formControlName="confirmNewPassword"
                    [feedback]="false"
                    (onBlur)="matchPassword()"
                    toggleMask="true"
                  ></p-password>
                </div>
              </div>
              <div
                class="form-errors"
                *ngIf="resetPasswordForm.controls.confirmNewPassword.errors && (resetPasswordForm.controls.confirmNewPassword.dirty || resetPasswordForm.controls.confirmNewPassword.touched)">
                <div
                  [hidden]="!resetPasswordForm.controls.confirmNewPassword.errors.required"
                  translate>
                  confirm_password_required
                </div>
              </div>
              <div class="form-errors" *ngIf="isPasswordMatch" translate>
                <div
                  *ngIf="!resetPasswordForm.controls.confirmNewPassword.value.length < 1 && resetPasswordForm.controls.confirmNewPassword.value != resetPasswordForm.controls.newPassword.value" translate>
                  err_password_confirm_password_match
                </div>
              </div>
            </div>

            <div class="form-buttons p-col-9">
              <button
                [disabled]="!resetPasswordForm.valid || resetPasswordForm.controls.confirmNewPassword.value != resetPasswordForm.controls.newPassword.value"
                pButton
                label="Submit"
                type="button"
                class="sign-in-button"
                (click)="resetPassword()"
              ></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="p-col-7 p-col-nogutter right-panel-div">
    <img
      class="right-panel-image"
      src="../../../assets/images/group-92.png"/>
  </div>
</div>

<p-toast></p-toast>
