import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorHandler } from './modules/shared/services/http-error-handler.service';
import { SharedModule } from './modules/shared/shared.module';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ForgotPassowrdComponent } from './pages/forgot-passowrd/forgot-passowrd.component';
import { PasswordModule } from 'primeng/password';
import { ToastModule } from 'primeng/toast';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ForgotPasswordEmailSentComponent } from './pages/forgot-password-email-sent/forgot-password-email-sent.component';
import { AuthorizationValidationService } from './modules/shared/services/authorization-validation.service';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';




@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    SignInComponent,
    ForgotPassowrdComponent,
    ResetPasswordComponent,
    ForgotPasswordEmailSentComponent,
    AccessDeniedComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PanelModule,
    InputTextModule,
    EditorModule,
    DialogModule,
    MultiSelectModule,
    AppRoutingModule,
    FieldsetModule,
    OverlayPanelModule,
    CalendarModule,
    FileUploadModule,
    ConfirmDialogModule,
    PasswordModule,
    ToastModule,
    SharedModule.forRoot()
  ],
  providers: [HttpErrorHandler, MessageService, AuthorizationValidationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
