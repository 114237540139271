import { Injectable } from '@angular/core';
import { RestApiService } from '../../services/rest-api.service';
import { Observable } from 'rxjs';


@Injectable()
export class GridService {

  constructor(private restApiService: RestApiService) { }

  public loadGridData(url: string): Observable<any> {
    return this.restApiService.get('mi-grid', url, 'page-center');
  }
}
